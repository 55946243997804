




















import Vue from 'vue'
import CanadianBankNames from '@/utils/data/canadian_bank_names'
import { IData, IMethods, IComputed, IProps } from '@/types/components/input/bank-autocomplete'

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  data() {
    return {
      bank: this.value,
      selected: null,
      hasError: false,
    }
  },
  props: {
    value: {
      type: String,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.bank
      },
      set(value) {
        this.bank = value
        this.$emit('input', value)
      }
    },
    sortedBankNames() {
      return CanadianBankNames.sort()
    },
    filteredBankNames() {
      return this.sortedBankNames.filter((option) => {
        return option.toLowerCase().indexOf((this.bank as string).toLowerCase()) >= 0
      })
    },
  },
  watch: {
    value(value) {
      this.bank = value
    }
  },
  methods: {
    validateInput() {
      this.hasError = !this.bank
    },
  },
})
